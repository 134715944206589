@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  overflow: hidden;
  text-align: center;
  background-color: #000;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}
/* DEBUG CODE */
/* * {
  outline: 1px solid red;
} */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
}

article h1 {
    margin: 1rem 0;
}

.navbar {
    padding-top: 1rem;
    position: fixed; /* Set the navbar to fixed position */
    background: rgba(0, 0, 0, 0.843);
    z-index: 10;
}
.smoothscroll{
    text-decoration: none;
    color: white;
    transition: 2s background-color;
    z-index: 10;
}
.nav-button{
    transition: 2s background-color;
}
.nav-button:hover {
    background-color: #45337D;
    transition: ease-in-out;
}
.navbar-item {
    text-decoration: none;
    color: white;
    font-size: 1.2em;
}
.navbar-item:hover {
    color: rgb(0, 0, 0);
}
.burger{
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.9);
}

  .logom360-s{
    padding-top: 8%;
  } 
.home-elements {
  padding-top: 6rem;
  padding-left: 4rem;
  text-align: left;
}
.home-elements-s {
  padding-top: 2rem;
  padding-left: 4rem;
  text-align: left;
}
.logo{
  padding-top: 10%;
  width: 60%;
  z-index: 1;
  margin-left: 3rem;
}
.floating{
  z-index: 20;
}
.p-text{
  display: flexbox;
  align-items: baseline;
  font-size: medium;
  margin-top: 2em;
}
.small {
  font-size: medium;
  margin: 1em 2em 0 0;
}
.left-s{
  margin-left: 3rem;
}
.right-s{
  margin-right: 3rem;
}
.contact-button{
  transition: 2s background-color;
}
.contact-button:hover {
  background-color: #45337D;
  transition: ease-in-out;
}
.text{
  padding-right: 5rem;
  padding-left: 2rem;
}
#next1{
  padding-left: 5rem;
}
.bar{
  float: right;
  width: 100vw;
}
.line1{
  max-width: 70vw;
}
.line3{
  padding-top: 3rem;
  padding-right: 1rem;
  padding-left: 60vw;
}
.text-dargrey{
  color: #272726;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  height: 1em;
  letter-spacing: 8px;
}
.text-grey{
  color: #959499;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  letter-spacing: 8px;
}
.text-white{
  color: #D3D4D8;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 4rem;
  height: 1em;
  letter-spacing: 8px;
}
.up-arrow{
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.space{
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.space-bottom{
  padding-bottom: 5rem;
}

.comic-text{
  width: 35rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  
}

/* Three image containers (use 25% for four, and 50% for two, etc) */
.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}
/* Two column containers*/
.twocolumn {
  float: left;
  width: 50%;
}

/* Clear floats after image containers */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.twocolumn-s {
  display: flex;
  width: 50%;
}
.comic-text-s{
  padding: 2rem;  
}

.image {
  display: block;
  margin-left: -2rem;
  margin-right: auto;
  width: 100%;
}

.text-background{
  background: rgb(0,0,0);
  background: linear-gradient(90deg, 
      rgba(0,0,0,1) 14%, 
      rgba(39,27,69,1) 34%,
      rgba(69,51,125,1) 42%,
      rgba(98,77,139,1) 50%,
      rgba(69,51,125,1) 58%,
      rgba(39,27,69,1) 65%, 
      rgba(0,0,0,1) 86%);
}
#work{
  padding-top: 10%;
}
.padding{
  margin-left: 0.5rem;
}
.background-image{
  background-image: url("/static/media/Line 3.79c546e1.png");
  background-repeat: no-repeat;
  background-size: 80% 60%;
  background-position: center;
}
.floating{
  position: fixed;
  top: 100%;
  left: 65%;
  z-index: 3;
  margin-top: -100px; /* Negative half of height. */
  margin-left: -250px; /* Negative half of width. */
}

#hide {
  position: fixed;
  top: 90%;
  left: 50%;
  z-index: 3;
}
.spacer{
  margin-bottom: 30vh;
}
.text-sml{
  padding: 2rem;
}
.images-sml{
  width: 80%;
}
.pgf{
  margin-top: 20%;
  
}
/* * {
  outline: 1px solid violet;
} */
/* unvisited link */
a:link {
    color: #d3d4d8;
}

/* visited link */
a:visited {
    color: 624D8B;
}

/* mouse over link */
a:hover {
    color: #271B45;
}

/* selected link */
a:active {
    color: #959499;
}
body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container{
    width: 100vw;
    overflow: hidden;
}
.background-image{
    background-image: url("/static/media/Line 3.79c546e1.png");
    background-repeat: no-repeat;
    background-size: 80% 60%;
    background-position: center;
}
.services-nav{
    padding-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.services-button{
    transition: 2s background-color;
}
.services-button:hover{
    background-color: #45337D;
    transition: ease-in-out;
}
.services-button-active{
    background-color: #45337D !important;
    transition: 2s background-color;
    width: 100%;
}
.services-button{
        width: 100%;
    }
.title {
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: -2rem;
    margin-top: -7%;

}
.title-strong {
    color: #45337D;
    padding-left: 0.5em;
}
.line {
    min-width: 100%;
    max-height: .2rem;
    /* margin-top: -2rem;
    margin-bottom: 2rem; */
}
.dinamic-text{
    font-size: 1em;
    text-align: left !important;
}
.title-s{
    font-size: 1.2rem;
    text-align: left;
    color: #959499;
}
.services-nav-s{
    display: flex;
    align-items: center;
    justify-content: center;
}
.hidden{
    visibility: hidden;
}
.left-arrow{
    margin-left: 2rem;
    margin-right: 2rem;
    width: 1rem;
}
.right-arrow{
    margin-right: 2rem;
    width: 1rem;
}
.line-s{
    padding-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    align-self: 'center';
    display: flex;
    width: 100vw; 
    align-items: center;
    justify-content: center;
}
/* * {
    outline: 1px solid rgb(177, 0, 38, 0.5);
}  */
.brochure-button {
    text-decoration: none;
    font-size: 1.2em;
    margin-left: 1rem;
    border: 2px solid #45337D;
    border-radius: 4rem;
    color: #45337D;
    background-color: #000;
    padding: 0.5rem;
}
.brochure-button:hover{
    border: 2px solid #45337D;
    color: #fff;
    background-color: #45337D;
}
.brochure-button-s {
    text-decoration: none;
    font-size: 1.2em;
    color: #000;
}
.brochure-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}
.brochure-bar-s {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
}
body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container {
    min-height:100vh;
    width: 100vw;
}
.container-s {
    min-height:100vh;
    width: 100vw;
    overflow: hidden;
}
.title-s{
    padding-bottom: 5%;
    font-size: 2em !important;
    text-align: center;
    color: aliceblue;
    margin-top: -7% !important;
}
dinamic-text-s{
    max-width: 50px;
}
.line-s{
    margin-top: -5%;
    margin-bottom: 10%;
}
.smaller-text{
    font-size: 0.8em;
}
.contact-button-s{
background-color: black !important;
max-height: 1.8rem;
}
.contact-button-s:hover{
background-color: #45337D  !important;
max-height: 1.8rem;
transition: .6s;
}
.contact-button{
    background-color: black !important;
    max-height: 2.4rem;
}
.contact-button:hover{
    background-color: #45337D  !important;
    transition: .6s;
}
.dinamic-img{
    width: 25vw;
}
.content-container{
    display: flex;
}
.link-container{
    flex-direction: row;
}
body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.form-container-s{
    width: 100%;
    padding-top: 5%;
}
.container{
    padding: 12%;
}
.container-s{
    margin-top: 12%;
    padding: 5%;
    text-align: left;
}
.white--header{
    margin-bottom: 2%;
}
.mobile{
    font-size: 1.5rem;
    padding: 1rem;
    text-align: left;
}
.form-container{
    display: flexbox;
    align-items: left;
    justify-content: left;
    padding-left: 2em;
    margin-bottom: -2rem;
    text-align: left;
}
.footer {
    padding-top: 5%;
}
.FormText--button {
    float: right;
}
.FormText{
    padding-left: 2%;
    font-size: 1.2rem;
    float:left;
}
input[type=text], input[type=email] {
    border: 2px solid #45337D;
    border-radius: 2rem;
    color: #45337D;
    transition: width 2s ease-in-out;
    height: auto;
    padding: 0.7em;
    padding-left: 2%;
    margin-left: 2rem;
    background-color: black;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 35%;
}
input::-webkit-input-placeholder {
    font-weight: bold;
    opacity: .8;
    color: #45337D;
    font-size: 1.9em;
}
input::placeholder {
    font-weight: bold;
    opacity: .8;
    color: #45337D;
    font-size: 1.9em;
}
.FormText--button{
    position: relative;
    display: block;
    width: 200px;
    height: 36px;
    border-radius: 18px;
    background-color: #45337D;
    border: solid 1px transparent;
    color: #000;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
    transition: all .1s ease-in-out;
}
.FormText--button:hover {
    background-color: transparent;
    border-color: #fff;
    transition: all .1s ease-in-out;
    color: #45337D;
}
.FormInput{
    float:left;
    display: flex;
    padding: 0;
    margin: 10px;
    padding: 0 !important;
    margin-left: 0;
    min-width: 54%
}
.FormInput::-webkit-input-placeholder{
    opacity: .8;
    color: #45337D;
    font-size: 1rem;
}
.FormInput::placeholder{
    opacity: .8;
    color: #45337D;
    font-size: 1rem;
}
.inputi{
    min-width: 50%;
}
.inputiii{
    min-width: 85%;
}
.FormInput{
    width: 100vw;
    font-size: 1.9rem;
}
.FormInput::-webkit-input-placeholder {
    font-weight: bold;
    color: #45337D;
    font-size: 1.4rem;
}
.FormInput::placeholder {
    font-weight: bold;
    color: #45337D;
    font-size: 1.4rem;
}
body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    font-size: smaller;
    text-align: left;
}
.story-container{
    display: flex;
    flex-direction: row;
    padding-bottom: 15%;
}
.story-padding-left{
    padding-left: 20%;
}
.story-padding-right{
    padding-right: 20%;
}
.story-paragraph{
    padding-top: 15%;
}
.story-padding{
    padding-top: 5%;
}
.left-align{
    text-align: left !important;
}
.background-image{
    border-radius: 40rem;
    background: linear-gradient(90deg, 
    rgba(69,51,125,1) 58%,
    rgba(39,27,69,1) 65%, 
    rgba(0,0,0,1) 86%);
}
.top-space-s{
    margin-top: 10%;
}
.story-paragraph-s{
    margin: 15% 25% 0% 25%;
    font-size: large;
}
.story-image-s{
    margin: 5% 0 10% 0;
}
.story-container-s{
    display: block;
}
.story-paragraph-s{
    margin: 5% 5% 0% 5%;
    font-size: medium;
}
.left-align{
    text-align: left;
    margin: 0 10% 0 10%
}
.inverted-background{
    border-radius: 40rem;
    background: linear-gradient(90deg, 
    rgba(0,0,0,1) 18%,
    rgba(39,27,69,1) 65%, 
    rgba(69,51,125,1) 86%);
}
.container-s{
    margin-bottom: 30vh;
}
.center-align{
    text-align: center;
}
/* DEBUG CODE */
/*
*{
    outline: 1px solid red;
}
*/
body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
p {
    font-size: smaller;
    text-align: left;
}
.story-container{
    display: flex;
    flex-direction: row;
    padding-bottom: 15%;
}
.story-padding-left{
    padding-left: 20%;
}
.story-padding-right{
    padding-right: 20%;
}
.story-paragraph{
    padding-top: 15%;
}
.story-padding{
    padding-top: 5%;
}
.left-align{
    text-align: left !important;
}
.background-image{
    border-radius: 40rem;
    background: linear-gradient(90deg, 
    rgba(69,51,125,1) 58%,
    rgba(39,27,69,1) 65%, 
    rgba(0,0,0,1) 86%);
}
.top-space-s{
    margin-top: 10%;
}
.story-paragraph-s{
    margin: 15% 20% 0% 20%;
    font-size: large;
}
.story-image-s{
    margin: 5% 0 10% 0;
}
.story-container-s{
    display: block;
}
.story-paragraph-s{
    margin: 5% 5% 0% 5%;
    font-size: medium;
}
.left-align{
    text-align: left;
    margin: 0 10% 0 10%
}
.inverted-background{
    border-radius: 40rem;
    background: linear-gradient(90deg, 
    rgba(0,0,0,1) 18%,
    rgba(39,27,69,1) 65%, 
    rgba(69,51,125,1) 86%);
}
.container-s{
    margin-bottom: 30vh;
}
.center-align{
    text-align: center;
}
/* DEBUG CODE */
/*
*{
    outline: 1px solid red;
}
*/
