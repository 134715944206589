@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: 1rem;
}

article h1 {
    margin: 1rem 0;
}

.navbar {
    padding-top: 1rem;
    position: fixed; /* Set the navbar to fixed position */
    background: rgba(0, 0, 0, 0.843);
    z-index: 10;
}
.smoothscroll{
    text-decoration: none;
    color: white;
    transition: 2s background-color;
    z-index: 10;
}
.nav-button{
    transition: 2s background-color;
}
.nav-button:hover {
    background-color: #45337D;
    transition: ease-in-out;
}
.navbar-item {
    text-decoration: none;
    color: white;
    font-size: 1.2em;
}
.navbar-item:hover {
    color: rgb(0, 0, 0);
}
.burger{
    width: 100vw;
    background-color: rgba(255, 255, 255, 0.9);
}